import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";

export type MonthlyGrnDataHash = {
  received_at: string;
  quantity: number;
}

export type StockSalesReportDataHash = {
  unique_key : number,
  sku: string,
  price: number,
  fbv_enabled: boolean,
  title: string,
  category: string,
  product_image: string | null,
  monthly_grn_data: MonthlyGrnDataHash[],
  monthly_received_quantity: number,
  total_received_quantity: number,
  monthly_rto_quantity: number,
  total_rto_quantity: number,
  monthly_return_quantity: number,
  total_return_quantity: number,
  monthly_order_quantity: number,
  total_order_quantity: number,
  sold_quantity: number,
  return_rto_quantity: number,
  sales_amount: number,
  current_stock_quantity: number
}

type StockSalesReportData = {
  stock_sales_data: StockSalesReportDataHash[];
  available_categories: string[]
}

type StockSalesReportApiState = {
  stockSalesReportData?: StockSalesReportData | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof StockSalesReportDataHash | null;
  stockSalesReportStatus: "idle" | "loading" | "failed";
  stockSalesReportError: string | null;
};

const initialState: StockSalesReportApiState = {
  stockSalesReportData: null,
  stockSalesReportStatus: "loading",
  stockSalesReportError: null,
  sortDirection: 'asc',
  sortedColumn: null
};

type ErrorResponse = {
  errors: string;
};


const formatRequest = (requestData: any, download:boolean = false): string => {
  let url = `/forge/stocks/stock_sales_report${download ? '.csv' : ''}?`;
  url += `from_date=${requestData.stockSalesReportMonthYearStartDateFilter}&`;
  url += `to_date=${requestData.stockSalesReportMonthYearEndDateFilter}&`;
  url += `category=${requestData.stockSalesReportCategoryFilter}&`;
  url += `product_id=${requestData.stockSalesReportProductIdFilter}`;
  return url;
}

export const stockSalesReportApi = createAsyncThunk(
  "stockSalesReportApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const formattedRequestUrl = formatRequest(requestData);
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const stockSalesReportDownloadReportApi = createAsyncThunk(
  "stockSalesReportDownloadReportApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const formattedRequestUrl = formatRequest(requestData, true);
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;
      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

const stockSalesReportSlice = createSlice({
  name: 'stockSalesReport',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof StockSalesReportDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      if(state.stockSalesReportData && column){
        const sorted = sortRecords(state.stockSalesReportData.stock_sales_data, column, direction);
        state.stockSalesReportData.stock_sales_data = sorted as StockSalesReportDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(stockSalesReportApi.pending, (state) => {
        state.stockSalesReportStatus = "loading";
        state.stockSalesReportError = null;
      })
      .addCase(
        stockSalesReportApi.fulfilled,
        (state, action: PayloadAction<StockSalesReportData>) => {
          state.stockSalesReportStatus = "idle";
          state.stockSalesReportData = action.payload;
        }
      )
      .addCase(stockSalesReportApi.rejected, (state, action) => {
        state.stockSalesReportStatus = "failed";
        if (action.payload) {
          state.stockSalesReportError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.stockSalesReportError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = stockSalesReportSlice.actions;
export default stockSalesReportSlice.reducer;
