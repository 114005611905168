import { Box, Button, MenuItem, Select, SwipeableDrawer, useMediaQuery, useTheme } from "@mui/material";
import styles from "./MonthSelector.module.scss";
import ModalBox from "components/atoms/ModalBox/ModalBox";
import { useEffect, useState } from "react";
import ColorButton from "components/atoms/ColorButton/ColorButton";
import { csx } from "helpers/utils";

type MonthYearType = {
  month: number | undefined;
  year: number | undefined;
}

const monthLabel = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const get21CenturyYears = (() => {
  const currYear = (new Date()).getFullYear();
  const years = [];
  for(let i = currYear; i >= 2000 ; i -= 1){
    years.push(i);
  }
  return years;
})();

type PropType = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  updateMonthYear: (monthYear: MonthYearType) => void;
  header?: React.ReactNode;
  allowClear?: boolean;
} & MonthYearType;


type SelectAreaPropType = {
  year: number;
  month: number;
  handleMonthYearChange: (_month: number, _year: number) => void;
}

const SelectArea:React.FC<SelectAreaPropType> = ({year, month, handleMonthYearChange}) => {

  const currDate = new Date();
  const isCurrentMonthSelected = currDate.getFullYear() === year && currDate.getMonth() === month;
  const isPrevMonthSelected = currDate.getMonth() === 0 ? (month === 11 && (currDate.getFullYear() === year + 1)) : (currDate.getFullYear() === year && currDate.getMonth() === month + 1);

  const [currYr, setCurrYr] = useState<number>(currDate.getFullYear());

  const onLastMonthClick = () => {
    if(currDate.getMonth() === 0 ){
      handleMonthYearChange(11, currDate.getFullYear() - 1);
    }else{
      handleMonthYearChange(currDate.getMonth() - 1, currDate.getFullYear());
    }
  }
  
  const onCurrentMonthClick = () => {
    handleMonthYearChange(currDate.getMonth(), currDate.getFullYear());
  }

  useEffect(() => {
    setCurrYr(year);
  }, [year]);
  
  return (
    <>
      <div className={styles['static-month-pickers']}>
        <div className={csx(styles['static-picker-btn'], isPrevMonthSelected ? styles['selected'] : '')} onClick={onLastMonthClick}>Last Month</div>
        <div className={csx(styles['static-picker-btn'], isCurrentMonthSelected ? styles['selected'] : '')} onClick={onCurrentMonthClick}>This Month</div>
      </div>
      <div className={styles['dynamic-month-picker']}>
          <Select
            value={currYr}
            onChange={(e) => setCurrYr(e.target.value as number)}
            variant={"standard"}
            disableUnderline
            className={styles['year-select']}
            MenuProps={{
              style: {
                maxHeight: 400,
              }
            }}
          >
            {get21CenturyYears.map(_year => <MenuItem key={_year} value={_year} dense>{_year}</MenuItem>)}
          </Select>
          <div className={styles['month-select']}>
            {monthLabel.map((label, _month) => {
              const isDisabled = (currDate.getFullYear() < currYr) || (currDate.getFullYear() === currYr && currDate.getMonth() < _month);
              const isSelected = _month === month && currYr === year;
              return (
                <Button
                  variant='text'
                  key={label}
                  className={csx(styles['month-btn'], isSelected ? styles['selected']: '', isDisabled ? styles['disabled'] : '')} 
                  disabled={isDisabled}
                  onClick={() => handleMonthYearChange(_month, currYr)}
                >{label}</Button>
            )})}
          </div>
      </div>
    </>
)};


const MonthSelector:React.FC<PropType> = ({month, year, open, onClose, updateMonthYear, header = null, allowClear = false, onOpen}) => {

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [currMonthYear, setCurrMonthYear] = useState<MonthYearType>({
    month: undefined,
    year: undefined
  });

  useEffect(() => {
    if(month === undefined){
      const currDate = new Date();
      setCurrMonthYear({month: currDate.getMonth()  , year: currDate.getFullYear()});
    }else{
      setCurrMonthYear({month  , year});
    }
  }, [month, year, open]);


  const onSubmit = () => {
    onClose();
    updateMonthYear({...currMonthYear});
  }

  const onClear = () => {
    updateMonthYear({
      month: undefined,
      year: undefined,
    });
  }

  const handleMonthYearChange = (_month: number, _year: number) => {
    setCurrMonthYear({
      year: _year,
      month: _month,
    });
  }

  const handleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }
    open ? onOpen() : onClose();
  };

  if(isMobile){
    return (
      <SwipeableDrawer
          anchor={"bottom"}
          open={open}
          onClose={handleDrawer(false)}
          onOpen={handleDrawer(true)}
      >
        <div className={styles['swipeable-mobile-drawer']}>
          <div className={styles['picker-header']}>
            {header}
          </div>
          <div className={styles['month-picker-wrapper']}>
            <SelectArea
              month={currMonthYear.month as number} 
              year={currMonthYear.year as number} 
              handleMonthYearChange={handleMonthYearChange}
            />
          </div>
          <div className={styles['picker-footer']}>
            {allowClear && (
              <ColorButton bgColor="#FFF" className={styles['clear-button']} variant="outlined" onClick={onClear}>
                Clear
              </ColorButton>
            )}
            <ColorButton bgColor="#3361FF" bgHoverColor="#2E58E8" className={styles['submit-button']} onClick={onSubmit}>
              Apply
            </ColorButton>
          </div>
        </div>
      </SwipeableDrawer>
    );
  }

  return (
    <ModalBox
      open={open}
      onClose={onClose}
      className={styles['picker-modal']}
    >
    <div>
        <div className={styles['picker-header']}>
          {header}
        </div>
        <div className={styles['month-picker-wrapper']}>
          <SelectArea
            month={currMonthYear.month as number} 
            year={currMonthYear.year as number} 
            handleMonthYearChange={handleMonthYearChange}
          />
        </div>
        <div className={styles['picker-footer']}>
          {allowClear && (
            <ColorButton bgColor="#FFF" className={styles['clear-button']} variant="outlined" onClick={onClear}>
              Clear
            </ColorButton>
          )}
          <ColorButton bgColor="#3361FF" bgHoverColor="#2E58E8" className={styles['submit-button']} onClick={onSubmit}>
            Apply
          </ColorButton>
        </div>
      </div>
    </ModalBox>
  );
}

export default MonthSelector;