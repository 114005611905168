import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {Box, Button, Fab, useMediaQuery, useTheme} from '@mui/material';
import { RootState } from '../../store';
import styles from './LorReport.module.scss';
import { setSort, lorReportApi, lorReportDownloadReportApi, LorReportDataHash, markNonReplenishableProductsApi, bulkMarkNonReplenishableProductsApi } from "../../slices/lorReportSlice";
import FbvIcon from '../../images/fbv-icon.png';
import Loader from "components/atoms/Loader/Loader";
import { setLorReportSelectDaysFilter, setLorReportProductNameFilter, setLorReportProductIdFilter, setLorReportFbvEnabledFilter, setLorReportFbvWarehouseFilter, resetFilter, setLorReportShowHistoricalDataFilter } from "../../slices/filtersSlice";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { resetAuth } from "../../slices/authSlice";
import TableManager from "../../components/organisms/TableManager/TableManager";
import { ConfirmationModal, fixedColumn, lorReportColumns, TableActionBar } from "./LorReport.constant";
import { downloadFile } from "../../helpers/utils";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "components/molecules/SearchByFilter/SearchByFilter";
import SelectFilter from "components/molecules/SelectFilter/SelectFilter";
import CheckboxFilter from "components/atoms/CheckboxFilter/CheckboxFilter";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ColorButton from "components/atoms/ColorButton/ColorButton";
import SwipeableFilters from "components/organisms/SwipeableFilter/SwipeableFilter";
import ModalBox from "components/atoms/ModalBox/ModalBox";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import { DEFAULT_BLACK_COLOR, ROUTES } from "../../constants";
import FileUpload from "components/molecules/FileUpload/FileUpload";
import { NotificationType, showNotification } from "slices/notificationSlice";
import { useNavigate } from "react-router-dom";
import MultiSelectFilter from 'components/molecules/MultiSelectFilter/MultiSelectFilter';

const searchByOptions = [
  {id: 'productId', label: 'Product ID'},
  {id: 'productName', label: 'Product Name'},
]

const lossOfRevenueByDaysOptions = [
  {label: 'No Selection', value: ''},
  {label: '7 Days', value: '7 Days'},
  {label: '15 Days', value: '15 Days'},
  {label: '30 Days', value: '30 Days'},
  {label: '60 Days', value: '60 Days'},
  {label: '90 Days', value: '90 Days'},
];

const PAGE_TITLE = 'LOR Report'

const LorReport = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fbv = useAppSelector((state) => state.auth.basicUserInfo?.fbv || false);

  const [filterOpen, setFilterOpen] = React.useState(false);
  const lorReportData = useAppSelector((state) => state.lorReport);
  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  const [showConfirmationPop, setShowConfirmationPopup] = React.useState<boolean>(false);
  const [showFileUploadPopup, setShowFileUploadPopup] = React.useState<boolean>(false);

  const [selectedIds, setSelectedIds] = React.useState<Set<string|number>>(new Set());
  const [pvIds, setPvIds] = React.useState<(string|number)[]>([]);

  const handleSort = (column: keyof LorReportDataHash) => {
    const direction = column === lorReportData.sortedColumn && lorReportData.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };

  useEffect(() => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.PAGE_VISIT, 
      {
        page_link: window.location.href, 
        page_title: PAGE_TITLE
      }
    );
  }, []);

  const downloadReport = async (fab?: boolean) => {
    const button = document.getElementById('download-button');
    if (button) {
      if(!fab) button.innerHTML = 'Downloading...';
      const userInfo = localStorage.getItem('userInfo');
      if(userInfo) {
        const token = JSON.parse(userInfo).token
        const headers = token ? { Authorization: `${token}` } : undefined;
        const downloadApi = () => dispatch(lorReportDownloadReportApi({productId: productIdFilter, productName: productNameFilter, selectDays: selectDaysFilter, headers: headers, fbvEnabled: lorReportFbvEnabledFilter, fbvWarehouse: lorReportFbvWarehouseFilter, lorReportShowHistoricalDataFilter}));
        const success = await downloadFile({downloadApi, fileName: 'report.csv', fileType: 'text/csv'})
        if(success){
          triggerMixpanelEvent(
            MIXPANEL_EVENT_TYPES.DOWNLOAD,
            {
              report_name: PAGE_TITLE,
              download_type: 'Report'
            }
          );
        }
      }
      if(!fab) button.innerHTML = 'Download';
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const selectDaysFilter = useAppSelector((state: RootState) => state.filters.lorReportSelectDaysFilter);
  const productNameFilter = useAppSelector((state: RootState) => state.filters.lorReportProductNameFilter);
  const productIdFilter = useAppSelector((state: RootState) => state.filters.lorReportProductIdFilter);
  const lorReportFbvEnabledFilter = useAppSelector((state: RootState) => state.filters.lorReportFbvEnabledFilter);
  const lorReportShowHistoricalDataFilter = useAppSelector((state: RootState) => state.filters.lorReportShowHistoricalDataFilter);
  const lorReportFbvWarehouseFilter = useAppSelector((state: RootState) => state.filters.lorReportFbvWarehouseFilter)
  
  const warehouseOptions = useAppSelector((state) => (state.filters.fbvWarehouseFilter || []).map((c) => ({ label: c.name, value: c.name })));
  
  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token;
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(lorReportApi({productId: productIdFilter, productName: productNameFilter, selectDays: selectDaysFilter, headers: headers, fbvEnabled: lorReportFbvEnabledFilter, lorReportShowHistoricalDataFilter, fbvWarehouse: lorReportFbvWarehouseFilter,})).unwrap();
      setPage(0);
      setSelectedIds(new Set());
      setLoaderActive(false);
      setFilterApplied(false);
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const markNonReplenishableProducts = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      try{
        const token = JSON.parse(userInfo).token;
        const headers = token ? { Authorization: `${token}` } : undefined;
        const payload = {product_variant_ids: pvIds}
        await dispatch(markNonReplenishableProductsApi({payload, headers}));
        setPvIds([]);
        setFilterApplied(true);
      }catch(e){
        setPvIds([]);
        console.error(e);
      }
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  }

  const downloadSampleFile = () => {
    const csvContent = "SKU\nSKU-1\nSKU-2\nSKU-3";
    const csvBlob = new Blob([csvContent], {type: 'text/csv'});
    const csvUrl = URL.createObjectURL(csvBlob);
    const link = document.createElement('a');
    link.setAttribute('href', csvUrl);
    link.setAttribute('download', 'sample.csv');
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const uploadBulkRemoveSkuFile = async (file: File) => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      try{
        dispatch(showNotification({type: NotificationType.Info, message: 'Uploading file...'}));
        const token = JSON.parse(userInfo).token;
        const headers = token ? { Authorization: `${token}` } : undefined;
        const payload = new FormData();
        payload.set('csv_file',file);
        await dispatch(bulkMarkNonReplenishableProductsApi({payload, headers}));
        navigate(ROUTES.REQUEST_TRACKING);
      }catch(e){
        console.error(e);
      }
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  }

  const trackFilterEvent = async () => {
    if(appliedSearchFilters.length === 0) return;
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_INTERACTION,
      {
        report_name: PAGE_TITLE,
        filter: [...appliedSearchFilters.map(f => f.label)]
      }
    )
  }

  useEffect(() => {
    if(filterApplied){  
      trackFilterEvent();
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const clearFilters = () => {
    dispatch(resetFilter());
    setFilterApplied(true);
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };


  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const appliedSearchFilters: AppliedFilterType[] = [
    ...(!!productIdFilter ? [{id: 'productId', label: 'Product ID', value: productIdFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!productNameFilter ? [{id: 'productName', label: 'Product Name', value: productNameFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!selectDaysFilter ? [{id: 'lorDays', label: 'LOR by days', value: selectDaysFilter, options: lossOfRevenueByDaysOptions, type: 'select'}] : []) as AppliedFilterType[],
    ...(!!lorReportFbvEnabledFilter ? [{id: 'fbvEnabled', label: 'FBV', value: productNameFilter, type: 'checkbox'}] : []) as AppliedFilterType[],
    ...(!!lorReportShowHistoricalDataFilter ? [{id: 'showHistoricalData', label: 'Show Historical Data', value: lorReportShowHistoricalDataFilter, type: 'checkbox'}] : []) as AppliedFilterType[],
    ...(!!lorReportFbvWarehouseFilter.length ? [{id: 'fbvWarehouse', label: 'FBV Warehouse', value: lorReportFbvWarehouseFilter, type: 'multi-select', options: warehouseOptions}] : []) as AppliedFilterType[],
  ];


  const handleFilterValueChange = ({id, value}: {id: string, value: string | boolean | string[]}) => {
    switch(id){
      case 'productId': {
        dispatch(setLorReportProductIdFilter(value as string)); break;
      }
      case 'productName': {
        dispatch(setLorReportProductNameFilter(value as string)); break;
      }
      case 'lorDays': {
        dispatch(setLorReportSelectDaysFilter(value as string)); break;
      }
      case 'fbvEnabled': {
        dispatch(setLorReportFbvEnabledFilter(value as boolean)); break;
      }
      case 'showHistoricalData': {
        dispatch(setLorReportShowHistoricalDataFilter(value as boolean)); break;
      }
      case 'fbvWarehouse' :{
        dispatch(setLorReportFbvWarehouseFilter(value as string[])); break;
      }
    }
    setFilterApplied(true);
  }

  const onSearchByClear = (id: string) => {
    if(id === 'fbvEnabled' || id === 'showHistoricalData')
      handleFilterValueChange({id, value: false});
    else if(id === 'fbvWarehouse')
      handleFilterValueChange({id, value: []});
    else 
      handleFilterValueChange({id, value: ''});
  }

  const onSearchByAllClear = () => {
    dispatch(setLorReportProductIdFilter(''));
    dispatch(setLorReportProductNameFilter(''));
    dispatch(setLorReportSelectDaysFilter('')); 
    dispatch(setLorReportFbvEnabledFilter(false)); 
    dispatch(setLorReportShowHistoricalDataFilter(false)); 
    dispatch(setLorReportFbvWarehouseFilter([]));
    setFilterApplied(true);
  }

  const handleSwipeableDrawerFilters = (idValueMap: Record<string, (string | boolean| string[])>) => {
    for(const [key, value] of Object.entries(idValueMap)){
      switch(key){
        case 'lorDays' : {
          dispatch(setLorReportSelectDaysFilter(value as string)); break;
        }
        case 'fbvEnabled' : {
          dispatch(setLorReportFbvEnabledFilter(value as boolean)); break;
        }
        case 'showHistoricalData' : {
          dispatch(setLorReportShowHistoricalDataFilter(value as boolean)); break;
        }
        case 'fbvWarehouse' :{
          dispatch(setLorReportFbvWarehouseFilter(value as string[])); break;
        }
      }
    }
    setFilterApplied(true);
  }

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label="LOR Report" />

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />

          {!isMobile? (
            <>
              <SelectFilter
                label={'LOR by days'}
                value={selectDaysFilter}
                options={lossOfRevenueByDaysOptions}
                onChange={(value) => {
                  dispatch(setLorReportSelectDaysFilter(value));
                  setFilterApplied(true);
                }}
              />
              {fbv && <MultiSelectFilter
                  label={'FBV Warehouse'}
                  values={lorReportFbvWarehouseFilter}
                  options={warehouseOptions}
                  onSubmit={(val) => {
                    dispatch(setLorReportFbvWarehouseFilter(val));
                    setFilterApplied(true);
                  }}
                />
              }
              <CheckboxFilter
                  checked={lorReportShowHistoricalDataFilter}
                  label={<p className={styles.checkboxTxt}>Show Historical Data</p>} 
                  onChange={(val) => {
                    dispatch(setLorReportShowHistoricalDataFilter(val));
                    setFilterApplied(true);
                  }}
                />
            </>
          ): (
            <Fab variant="extended" size="small" className={styles.filterFAB} onClick={() => setFilterOpen(true)}>
              <FilterAltOutlinedIcon fontSize="small" />
              Filter
            </Fab>
          )}
        </Box>
        {!isMobile ? (
          <>
            <ButtonX className={styles.bulkRemoveBtn} color={DEFAULT_BLACK_COLOR} variant="outlined" size="small" onClick={() => setShowFileUploadPopup(true)}>
              Bulk Remove
            </ButtonX>
            <Button
              id="download-button"
              className={styles.downloadBtn}
              onClick={() => downloadReport()}
            >
              Download
            </Button>
          </>
        ):(
          <Fab className={styles.downloadFAB} onClick={() => downloadReport(true)} size='medium' id="download-button">
            <FileDownloadOutlinedIcon fontSize='small' />
          </Fab>
        )}
      </Box>
        <SwipeableFilters
          open={filterOpen}
          onOpen={() => setFilterOpen(true)}
          onClose={() => setFilterOpen(false)}
          onAction={handleSwipeableDrawerFilters}
          selectFilters={[
            {
              id: 'lorDays',
              label: 'LOR by days',
              type: 'select',
              value: selectDaysFilter,
              options: lossOfRevenueByDaysOptions,
            },
          ]}
          checkboxFilters={[
            {
                id: 'showHistoricalData',
                label: 'Show Historical Data',
                type: 'checkbox',
                value: lorReportShowHistoricalDataFilter,
            }
          ]}
          multiSelectFilters={[
            ...(fbv ? [
              {
                id: 'fbvWarehouse',
                label: 'FBV Warehouse',
                type: 'multiSelect' as 'multiSelect',
                value: lorReportFbvWarehouseFilter,
                options: warehouseOptions,
              }
            ] : [])
          ]}
        />
      {appliedSearchFilters.length ? (
        <SearchSelections
          appliedFilters={appliedSearchFilters} 
          allClear={onSearchByAllClear} 
          onClear={onSearchByClear}
        />
      ): null}

      <Loader show={loaderActive} />

      {!loaderActive && (
        <TableManager<LorReportDataHash>
          data={lorReportData?.lorReportData?.lor_report_data || []}
          columns={lorReportColumns({isMultiSelect: selectedIds.size > 0, setPvIds, setShowConfirmationPopup})}
          sortedColumn={lorReportData.sortedColumn}
          handleSort={handleSort}
          sortDirection={lorReportData.sortDirection}
          showPagination
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
          fixedColumn={isMobile ? fixedColumn: undefined}
          selectionProps={{
            selected : selectedIds,
            updateSelection: setSelectedIds
          }}
          actionBarContent={
            selectedIds.size ? <TableActionBar handleRemoveAction={() => {
              setPvIds([...selectedIds]);
              setShowConfirmationPopup(true);
            }}/> : null
          }
        />
      )}

      <ConfirmationModal
        open={showConfirmationPop}
        onClose={() => {
          setPvIds([]);
          setShowConfirmationPopup(false);
        }}
        isSingleProduct={pvIds.length === 1}
        onDiscard={() => {
          setPvIds([]);
          setShowConfirmationPopup(false);
        }}
        onSubmit={() => {
          setShowConfirmationPopup(false);
          markNonReplenishableProducts();
        }}
      />
      <FileUpload
        open={showFileUploadPopup}
        label={"Remove Non-Replenishable SKUs in Bulk"}
        sampleFileHandler={downloadSampleFile}
        fileType={['text/csv']}
        multiple={false}
        onDiscard={() => setShowFileUploadPopup(false)}
        onSubmit={(files) => {
          uploadBulkRemoveSkuFile(files[0]);
        }}
      />
    </Box>
  );

};
  
export default LorReport;
