import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {Box, Button, Fab, useMediaQuery, useTheme} from '@mui/material';
import { RootState } from '../../store';
import styles from './SohReport.module.scss';
import { SohReportDataHash, setSort, sohReportApi, sohReportDownloadReportApi } from "../../slices/sohReportSlice";
import FbvIcon from '../../images/fbv-icon.png';
import Loader from "components/atoms/Loader/Loader";
import {setSohReportProductNameFilter, setSohReportProductIdFilter, setSohReportInclude0RosFilter, setSohReportFbvEnabledFilter, setSohReportFbvWarehouseFilter, resetFilter } from "../../slices/filtersSlice";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { resetAuth } from "../../slices/authSlice";
import TableManager from "../../components/organisms/TableManager/TableManager";
import { fixedColumn, sohReportColumns } from "./SohReport.constant";
import { downloadFile } from "../../helpers/utils";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "components/molecules/SearchByFilter/SearchByFilter";
import CheckboxFilter from "components/atoms/CheckboxFilter/CheckboxFilter";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ColorButton from "components/atoms/ColorButton/ColorButton";
import SwipeableFilters from "components/organisms/SwipeableFilter/SwipeableFilter";
import MultiSelectFilter from 'components/molecules/MultiSelectFilter/MultiSelectFilter';

const searchByOptions = [
  {id: 'productId', label: 'Product ID'},
  {id: 'productName', label: 'Product Name'},
]

const PAGE_TITLE = 'SOH Report'

const SohReport = () => {
  const dispatch = useAppDispatch();

  const fbv = useAppSelector((state) => state.auth.basicUserInfo?.fbv || false);

  const sohReportData = useAppSelector((state) => state.sohReport);
  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  const [filterOpen, setFilterOpen] = React.useState(false);

  const handleSort = (column: keyof SohReportDataHash) => {
    const direction = column === sohReportData.sortedColumn && sohReportData.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };

  useEffect(() => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.PAGE_VISIT, 
      {
        page_link: window.location.href, 
        page_title: PAGE_TITLE
      }
    );
  }, []);

  const downloadReport = async (fab?: boolean) => {
    const button = document.getElementById('download-button');
    if (button) {
      if(!fab) button.innerHTML = 'Downloading...';
      const userInfo = localStorage.getItem('userInfo');
      if(userInfo) {
        const token = JSON.parse(userInfo).token
        const headers = token ? { Authorization: `${token}` } : undefined;
        const downloadApi = () => dispatch(sohReportDownloadReportApi({productId: productIdFilter, productName: productNameFilter, headers: headers, include0Ros: sohReportInclude0RosFilter, fbvEnabled: sohReportFbvEnabledFilter, fbvWarehouse: sohReportFbvWarehouseFilter}));
        const success = await downloadFile({downloadApi, fileName: 'report.csv', fileType: 'text/csv'})
        if(success){
          triggerMixpanelEvent(
            MIXPANEL_EVENT_TYPES.DOWNLOAD,
            {
              report_name: PAGE_TITLE,
              download_type: 'Report'
            }
          );
        }
      }else{
        dispatch(resetAuth());
        dispatch(resetFilter());
      }
      if(!fab) button.innerHTML = 'Download';
    }
  };

  const productNameFilter = useAppSelector((state: RootState) => state.filters.sohReportProductNameFilter);
  const productIdFilter = useAppSelector((state: RootState) => state.filters.sohReportProductIdFilter);
  const sohReportInclude0RosFilter = useAppSelector((state: RootState) => state.filters.sohReportInclude0RosFilter);
  const sohReportFbvEnabledFilter = useAppSelector((state: RootState) => state.filters.sohReportFbvEnabledFilter);
  const sohReportFbvWarehouseFilter = useAppSelector((state: RootState) => state.filters.sohReportFbvWarehouseFilter)
  
  const warehouseOptions = useAppSelector((state) => (state.filters.fbvWarehouseFilter || []).map((c) => ({ label: c.name, value: c.name })));

  const appliedSearchFilters: AppliedFilterType[] = [
    ...(!!productIdFilter ? [{id: 'productId', label: 'Product ID', value: productIdFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!productNameFilter ? [{id: 'productName', label: 'Product Name', value: productNameFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!sohReportFbvEnabledFilter ? [{id: 'fbvEnaled', label: 'FBV', value: sohReportFbvEnabledFilter, type: 'checkbox'}] : []) as AppliedFilterType[],
    ...(!!sohReportInclude0RosFilter ? [{id: 'include0Ros', label: 'Include 0 ROS', value: sohReportInclude0RosFilter, type: 'checkbox'}] : []) as AppliedFilterType[],
    ...(!!sohReportFbvWarehouseFilter.length ? [{id: 'fbvWarehouse', label: 'FBV Warehouse', value: sohReportFbvWarehouseFilter, type: 'multi-select', options: warehouseOptions}] : []) as AppliedFilterType[],
  ];

  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(sohReportApi({productId: productIdFilter, productName: productNameFilter, include0Ros: sohReportInclude0RosFilter, fbvEnabled: sohReportFbvEnabledFilter, fbvWarehouse: sohReportFbvWarehouseFilter, headers: headers})).unwrap();
      setPage(0);
      setLoaderActive(false);
      setFilterApplied(false);
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const trackFilterEvent = async () => {
    if(appliedSearchFilters.length === 0) return;
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_INTERACTION,
      {
        report_name: PAGE_TITLE,
        filter: [...appliedSearchFilters.map(f => f.label)]
      }
    )
  }

  useEffect(() => {
    if(filterApplied){
      trackFilterEvent();
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const clearFilters = () => {
    dispatch(resetFilter());
    setFilterApplied(true);
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);


  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const handleFilterValueChange = ({id, value}: {id: string, value: string | boolean | string[]}) => {
    switch(id){
      case 'productId': {
        dispatch(setSohReportProductIdFilter(value as string)); break;
      }
      case 'productName': {
        dispatch(setSohReportProductNameFilter(value as string)); break;
      }
      case 'include0Ros': {
        dispatch(setSohReportInclude0RosFilter(value as boolean)); break;
      }
      case 'fbvEnabled': {
        dispatch(setSohReportFbvEnabledFilter(value as boolean)); break;
      }
      case 'fbvWarehouse' :{
        dispatch(setSohReportFbvWarehouseFilter(value as string[])); break;
      }
    }
    setFilterApplied(true);
  }

  const onSearchByClear = (id: string) => {
    if(id === 'include0Ros' || id === 'fbvEnabled')
      handleFilterValueChange({id, value: false});
    else if(id === 'fbvWarehouse')
      handleFilterValueChange({id, value: []});
    else
      handleFilterValueChange({id, value: ''});
  }

  const onSearchByAllClear = () => {
    dispatch(setSohReportProductIdFilter(''));
    dispatch(setSohReportProductNameFilter(''));
    dispatch(setSohReportInclude0RosFilter(false));
    dispatch(setSohReportFbvEnabledFilter(false));
    dispatch(setSohReportFbvWarehouseFilter([]));
    setFilterApplied(true);
  }

  const handleSwipeableDrawerFilters = (idValueMap: Record<string, (string | boolean| string[])>) => {
    for(const [key, value] of Object.entries(idValueMap)){
      switch(key){
        case 'include0Ros' : {
          dispatch(setSohReportInclude0RosFilter(value as boolean)); break;
        }
        case 'fbvEnabled' : {
          dispatch(setSohReportFbvEnabledFilter(value as boolean)); break;
        }
        case 'fbvWarehouse' :{
          dispatch(setSohReportFbvWarehouseFilter(value as string[])); break;
        }
      }
    }
    setFilterApplied(true);
  }

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label="SOH Report" />

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />

          {!isMobile? (
            <>
              {fbv && <MultiSelectFilter
                  label={'FBV Warehouse'}
                  values={sohReportFbvWarehouseFilter}
                  options={warehouseOptions}
                  onSubmit={(val) => {
                    dispatch(setSohReportFbvWarehouseFilter(val));
                    setFilterApplied(true);
                  }}
                />
              }
              <CheckboxFilter
                checked={sohReportInclude0RosFilter} 
                label={<p className={styles.checkboxTxt}>Include 0 ROS</p>}
                onChange={(val) => {
                  dispatch(setSohReportInclude0RosFilter(val));
                  setFilterApplied(true);
                }}
              />
            </>
          ): (
            <Fab variant="extended" size="small" className={styles.filterFAB} onClick={() => setFilterOpen(true)}>
              <FilterAltOutlinedIcon fontSize="small" />
              Filter
            </Fab>
          )}
        </Box>
        {!isMobile ? (
         <Button
            id="download-button"
            className={styles.downloadBtn}
            onClick={() => downloadReport()}
          >
            Download
          </Button>
        ):(
          <Fab className={styles.downloadFAB} onClick={() => downloadReport(true)} size='medium' id="download-button">
            <FileDownloadOutlinedIcon fontSize='small' />
          </Fab>
        )}
      </Box>
        <SwipeableFilters
          open={filterOpen}
          onOpen={() => setFilterOpen(true)}
          onClose={() => setFilterOpen(false)}
          onAction={handleSwipeableDrawerFilters}
          checkboxFilters={[
            {
              id: 'include0Ros',
              label: 'Include 0 ROS',
              type: 'checkbox',
              value: sohReportInclude0RosFilter,
            },
          ]}
          multiSelectFilters={[
            ...(fbv ? [
              {
                id: 'fbvWarehouse',
                label: 'FBV Warehouse',
                type: 'multiSelect' as 'multiSelect',
                value: sohReportFbvWarehouseFilter,
                options: warehouseOptions,
              }
            ] : [])
          ]}
      />
      {appliedSearchFilters.length ? (
        <SearchSelections
          appliedFilters={appliedSearchFilters} 
          allClear={onSearchByAllClear} 
          onClear={onSearchByClear}
        />
      ): null}

      <Loader show={loaderActive} />

      {!loaderActive && (
          <TableManager<SohReportDataHash>
            data={sohReportData?.sohReportData?.soh_report_data || []}
            columns={sohReportColumns()}
            sortedColumn={sohReportData.sortedColumn}
            handleSort={handleSort}
            sortDirection={sohReportData.sortDirection}
            showPagination
            currentPage={page}
            rowPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 100]}
            fixedColumn={isMobile ? fixedColumn: undefined}
          />
        )}
    </Box>
  );
};
  
export default SohReport;
