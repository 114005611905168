import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Box, Button, Fab,  Tab,  Tabs,  useMediaQuery, useTheme} from '@mui/material';
import { RootState } from '../../store';
import styles from './ProductCatalogue.module.scss';
import { setSort, productCatalogueApi, productCatalogueDownloadApi } from "../../slices/productCatalogueSlice";
import FbvIcon from '../../images/fbv-icon.png';
import Loader from "components/atoms/Loader/Loader";
import { 
  resetFilter,
  setProductCatalogueStatusFilter,
  ProductCatalogueStatusType,
  setProductCatalogueSkuFilter,
  setProductCatalogueTitleFilter,
  setProductCatalogueVinFilter,
  setProductCatalogueFbvEnabledFilter,
  setProductCatalogueCategoryFilter,
  setProductCatalogueCreatedAtStartDateFilter,
  setProductCatalogueCreatedAtEndDateFilter,
  setProductCatalogueFbvWarehouseFilter,
} from "../../slices/filtersSlice";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { resetAuth } from "../../slices/authSlice";
import {productCatalogueColumns } from "./ProductCatalogue.constant";
import TableManager from "../../components/organisms/TableManager/TableManager";
import SwipeableFilters from "components/organisms/SwipeableFilter/SwipeableFilter";
import { downloadFile } from "helpers/utils";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import DateRangeSelector, { DateRangeLabel } from "components/molecules/DateRangeSelector/DateRangeSelector";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "components/molecules/SearchByFilter/SearchByFilter";
import CheckboxFilter from "components/atoms/CheckboxFilter/CheckboxFilter";

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { DateRangeType, DEFAULT_BLACK_COLOR } from "../../constants";
import { ProductDataHash } from "slices/productCatalogueSlice";
import SelectFilter from "components/molecules/SelectFilter/SelectFilter";
import MultiSelectFilter from 'components/molecules/MultiSelectFilter/MultiSelectFilter';

const PAGE_TITLE = 'Product Catalog';

const searchByOptions = [
  {id: 'productName', label: 'Product Name'},
  {id: 'sku', label: 'SKU'},
  {id: 'vin', label: 'VIN'},
]

const ProductCatalogue = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  const [filterOpen, setFilterOpen] = useState(false);

  const fbv = useAppSelector((state) => state.auth.basicUserInfo?.fbv || false);
  const productCatalogueData = useAppSelector((state: RootState) => state.productCatalogue);
  const productCatalogueStatusFilter = useAppSelector((state: RootState) => state.filters.productCatalogueStatusFilter)
  const productCatalogueCreatedAtStartDateFilter = useAppSelector((state: RootState) => state.filters.productCatalogueCreatedAtStartDateFilter)
  const productCatalogueCreatedAtEndDateFilter = useAppSelector((state: RootState) => state.filters.productCatalogueCreatedAtEndDateFilter)
  const productCatalogueCategoryFilter = useAppSelector((state: RootState) => state.filters.productCatalogueCategoryFilter)
  const productCatalogueTitleFilter = useAppSelector((state: RootState) => state.filters.productCatalogueTitleFilter)
  const productCatalogueSkuFilter = useAppSelector((state: RootState) => state.filters.productCatalogueSkuFilter)
  const productCatalogueVinFilter = useAppSelector((state: RootState) => state.filters.productCatalogueVinFilter)
  const productCatalogueHsnCodeFilter = useAppSelector((state: RootState) => state.filters.productCatalogueHsnCodeFilter)
  const productCatalogueFbvEnabledFilter = useAppSelector((state: RootState) => state.filters.productCatalogueFbvEnabledFilter)
  const productCatalogueFbvWarehouseFilter = useAppSelector((state: RootState) => state.filters.productCatalogueFbvWarehouseFilter)

  const allProductCount = productCatalogueData.productCatalogueData?.product_variants.length || 0;
  const activeProductCount = productCatalogueData.productCatalogueData?.active_product_variants.length || 0;
  const draftProductCount = productCatalogueData.productCatalogueData?.draft_product_variants.length || 0;

  let tableData:ProductDataHash[] = []
  if(productCatalogueStatusFilter === 'all'){
    tableData = productCatalogueData.productCatalogueData?.product_variants || [];
  }else if(productCatalogueStatusFilter === 'active'){
    tableData = productCatalogueData.productCatalogueData?.active_product_variants || [];
  }else{
    tableData = productCatalogueData.productCatalogueData?.draft_product_variants || [];
  }

  const availableCategories = productCatalogueData.productCatalogueData?.available_categories || [];
  const categoryOptions = availableCategories.map((c) => ({label: c, value: c}));

  const warehouseOptions = useAppSelector((state) => (state.filters.fbvWarehouseFilter || []).map((c) => ({ label: c.name, value: c.name })));

  const appliedSearchFilters: AppliedFilterType[] = [
    ...(!!productCatalogueSkuFilter ? [{id: 'sku', label: 'SKU', value: productCatalogueSkuFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!productCatalogueTitleFilter ? [{id: 'productName', label: 'Product Name', value: productCatalogueTitleFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!productCatalogueVinFilter ? [{id: 'vin', label: 'VIN', value: productCatalogueVinFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!productCatalogueFbvEnabledFilter ? [{id: 'fbvEnabled', label: 'FBV', value: productCatalogueFbvEnabledFilter, type: 'checkbox'}] : []) as AppliedFilterType[],
    ...(!!productCatalogueCategoryFilter ? [{id: 'category', label: 'Category', value: productCatalogueCategoryFilter, type: 'select', options: categoryOptions}] : []) as AppliedFilterType[],
    ...(!!productCatalogueFbvWarehouseFilter.length ? [{id: 'fbvWarehouse', label: 'FBV Warehouse', value: productCatalogueFbvWarehouseFilter, type: 'multi-select', options: warehouseOptions}] : []) as AppliedFilterType[],
  ];

  const handleSort = (column: keyof ProductDataHash) => {
    const direction = column === productCatalogueData.sortedColumn && productCatalogueData.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };


  useEffect(() => {
    if(!!productCatalogueCategoryFilter && !availableCategories.includes(productCatalogueCategoryFilter)){
      setProductCatalogueCategoryFilter('');
    }
  }, [productCatalogueData.productCatalogueData?.available_categories])


  useEffect(() => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.PAGE_VISIT, 
      {
        page_link: window.location.href,
        page_title: PAGE_TITLE
      }
    );
  }, []);

  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(productCatalogueApi({
        productCatalogueCreatedAtStartDateFilter,
        productCatalogueCreatedAtEndDateFilter,
        productCatalogueCategoryFilter,
        productCatalogueTitleFilter,
        productCatalogueSkuFilter,
        productCatalogueVinFilter,
        productCatalogueHsnCodeFilter,
        productCatalogueFbvEnabledFilter,
        productCatalogueFbvWarehouseFilter,
        headers,
      })).unwrap();
      setPage(0);
      setFilterApplied(false);
      setLoaderActive(false);
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const downloadReport = async (fab?: boolean) => {
    const button = document.getElementById('download-button');
    if (button) {
      if(!fab) button.innerHTML = 'Downloading...';
      const userInfo = localStorage.getItem('userInfo');
      if(userInfo) {
        const token = JSON.parse(userInfo).token
        const headers = token ? { Authorization: `${token}` } : undefined;
        let downloadApi: () => Promise<any>  = () => dispatch(productCatalogueDownloadApi({
          productCatalogueCreatedAtStartDateFilter,
          productCatalogueCreatedAtEndDateFilter,
          productCatalogueCategoryFilter,
          productCatalogueTitleFilter,
          productCatalogueSkuFilter,
          productCatalogueVinFilter,
          productCatalogueHsnCodeFilter,
          productCatalogueFbvEnabledFilter,
          productCatalogueFbvWarehouseFilter,
          headers,
        }));
        const success = await downloadFile({downloadApi, fileName: 'report.csv', fileType: 'text/csv'})
        if(success){
          triggerMixpanelEvent(
            MIXPANEL_EVENT_TYPES.DOWNLOAD,
            {
              report_name: PAGE_TITLE,
              download_type: 'Report'
            }
          );
        }
      }else{
        dispatch(resetAuth());
        dispatch(resetFilter());
      }
      if(!fab) button.innerHTML = 'Download';
    }
  };

  const trackFilterEvent = async () => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_INTERACTION,
      {
        report_name: PAGE_TITLE,
        filter: [
          ...(productCatalogueCreatedAtStartDateFilter ? [ 'Product Created At'] : []),
          'Product Created At', ...appliedSearchFilters.map(f => f.label)
        ]
      }
    )
  }

  useEffect(() => {
    if(filterApplied){
      trackFilterEvent();
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };

  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);


  const handleFilterValueChange = ({id, value}: {id: string, value: string | boolean | string []}) => {
    switch(id){
      case 'sku': {
        dispatch(setProductCatalogueSkuFilter(value as string)); break;
      }
      case 'productName': {
        dispatch(setProductCatalogueTitleFilter(value as string)); break;
      }
      case 'vin': {
        dispatch(setProductCatalogueVinFilter(value as string)); break;
      }
      case 'fbvEnabled': {
        dispatch(setProductCatalogueFbvEnabledFilter(value as boolean)); break;
      }
      case 'category': {
        dispatch(setProductCatalogueCategoryFilter(value as string)); break;
      }
      case 'fbvWarehouse' :{
        dispatch(setProductCatalogueFbvWarehouseFilter(value as string[])); break;
      }
    }
    setFilterApplied(true);
  }

  const handleSwipeableDrawerFilters = (idValueMap: Record<string, (string | boolean| string[])>) => {
    for(const [key, value] of Object.entries(idValueMap)){
      switch(key){
        case 'fbvEnabled' : {
          dispatch(setProductCatalogueFbvEnabledFilter(value as boolean)); break;
        }
        case 'category' : {
          dispatch(setProductCatalogueCategoryFilter(value as string)); break;
        }
        case 'fbvWarehouse' :{
          dispatch(setProductCatalogueFbvWarehouseFilter(value as string[])); break;
        }
      }
    }
    setFilterApplied(true);
  }

  const onSearchByClear = (id: string) => {
    if(id === 'fbvEnabled')
      handleFilterValueChange({id, value: false});
    else if(id === 'fbvWarehouse')
      handleFilterValueChange({id, value: []});
    else
      handleFilterValueChange({id, value: ''});
  }

  const onSearchByAllClear = () => {
    dispatch(setProductCatalogueSkuFilter(''));
    dispatch(setProductCatalogueTitleFilter(''));
    dispatch(setProductCatalogueVinFilter(''));
    dispatch(setProductCatalogueFbvEnabledFilter(false));
    dispatch(setProductCatalogueCategoryFilter(''));
    dispatch(setProductCatalogueFbvWarehouseFilter([]));
    setFilterApplied(true);
  }

  const handleStartEndDateSelect = (ranges: DateRangeType) => {
    dispatch(setProductCatalogueCreatedAtStartDateFilter(ranges.startDate as Date));
    dispatch(setProductCatalogueCreatedAtEndDateFilter(ranges.endDate as Date));
    setFilterApplied(true);
  };

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label="Product Catalog">
        <DateRangeLabel
          startDate={productCatalogueCreatedAtStartDateFilter}
          endDate={productCatalogueCreatedAtEndDateFilter}
          onClick={() => {setShowDateRangeFilter(true)}}
          noDateLabel="Published Date"
        />
      </MainHeader>

      <Box className={styles.pageTabsWrapper}>
        <Tabs
          value={productCatalogueStatusFilter}
          onChange={(_, newValue) => {
            setPage(0);
            dispatch(setProductCatalogueStatusFilter(newValue as ProductCatalogueStatusType))
          }}
          TabIndicatorProps={{
            sx: {
              backgroundColor: '#4F76FE',
            }
          }}
        >
          <Tab label={`All (${allProductCount})`} value={'all'} style={{ textTransform: 'none', fontWeight:400, color: productCatalogueStatusFilter === 'all' ? '#3361FF' : DEFAULT_BLACK_COLOR}} />
          <Tab label={`Active (${activeProductCount})`} value={'active'} style={{ textTransform: 'none', fontWeight: 400, color: productCatalogueStatusFilter === 'active' ? '#3361FF' : DEFAULT_BLACK_COLOR}} />
          <Tab label={`Draft (${draftProductCount})`} value={'draft'} style={{ textTransform: 'none', fontWeight: 400, color: productCatalogueStatusFilter === 'draft' ? '#3361FF' : DEFAULT_BLACK_COLOR}} />
        </Tabs>
      </Box>

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />

          {!isMobile? (
            <>
              <SelectFilter 
                label={'Category'}
                value={productCatalogueCategoryFilter}
                options={categoryOptions}
                onChange={(val) => {
                  dispatch(setProductCatalogueCategoryFilter(val));
                  setFilterApplied(true);
                }}
              />
              {fbv && <MultiSelectFilter
                  label={'FBV Warehouse'}
                  values={productCatalogueFbvWarehouseFilter}
                  options={warehouseOptions}
                  onSubmit={(val) => {
                    dispatch(setProductCatalogueFbvWarehouseFilter(val));
                    setFilterApplied(true);
                  }}
                />
              }
            </>
          ): (
            <Fab variant="extended" size="small" className={styles.filterFAB} onClick={() => setFilterOpen(true)}>
              <FilterAltOutlinedIcon fontSize="small" />
              Filter
            </Fab>
          )}
        </Box>
        {!isMobile ? (
          <Button
            id="download-button"
            className={styles.downloadBtn}
            onClick={() => downloadReport()}
          >
            Download
          </Button>
        ):(
          <Fab className={styles.downloadFAB} onClick={() => downloadReport(true)} size='medium' id="download-button">
            <FileDownloadOutlinedIcon fontSize='small' />
          </Fab>
        )}
      </Box>
        <SwipeableFilters
          open={filterOpen}
          onOpen={() => setFilterOpen(true)}
          onClose={() => setFilterOpen(false)}
          onAction={handleSwipeableDrawerFilters}
          selectFilters={[{
            id: 'category',
            label: 'Category',
            type: 'select',
            value: productCatalogueCategoryFilter,
            options: categoryOptions,
          }]}
          multiSelectFilters={[
            ...(fbv ? [
              {
                id: 'fbvWarehouse',
                label: 'FBV Warehouse',
                type: 'multiSelect' as 'multiSelect',
                value: productCatalogueFbvWarehouseFilter,
                options: warehouseOptions,
              }
            ] : [])
          ]} 
      />
      {appliedSearchFilters.length ? (
        <SearchSelections
          appliedFilters={appliedSearchFilters} 
          allClear={onSearchByAllClear} 
          onClear={onSearchByClear}
        />
      ): null}

      <Loader show={loaderActive} />

      {!loaderActive && (
        <TableManager<ProductDataHash>
          data={tableData}
          columns={productCatalogueColumns(isMobile)}
          sortedColumn={productCatalogueData.sortedColumn}
          handleSort={handleSort}
          sortDirection={productCatalogueData.sortDirection}
          showPagination
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
          showHeader={false}
        />
      )}

      <DateRangeSelector
        open={showDateRangeFilter}
        onClose={() => {setShowDateRangeFilter(false)}}
        startDate={productCatalogueCreatedAtStartDateFilter}
        endDate={productCatalogueCreatedAtEndDateFilter}
        updateDateRange={handleStartEndDateSelect}
        header={'Select Date Range'}
        onOpen={() => {setShowDateRangeFilter(true)}}
        allowClear
      />
    </Box>
  );
};
  
export default ProductCatalogue;